import { useRef, useEffect, useCallback } from "react"
import type { AnimationItem, CanvasRendererConfig } from "lottie-web"
import Lottie from "lottie-web"
import type { HTMLAttributes, ReactNode } from "react"

type LottieAnimationProps = {
  fallback?: ReactNode
  path: string
  rendererSettings?: CanvasRendererConfig
} & HTMLAttributes<HTMLDivElement>

export function LottieAnimation(props: LottieAnimationProps) {
  const { className, path, fallback, rendererSettings, ...otherProps } = props

  const animationRef = useRef<AnimationItem | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  async function readAnimation(path: string) {
    try {
      const response = await fetch(path, {
        cache: "force-cache",
      })

      if (response.ok) {
        const data = await response.json()
        return data as object
      }

      return null
    } catch {
      return null
    }
  }

  async function createAnimation() {
    const animationData = await readAnimation(path)
    if (containerRef.current && animationData) {
      if (animationRef.current) animationRef.current.destroy()

      animationRef.current = await Lottie.loadAnimation({
        animationData,
        loop: true,
        autoplay: true,
        container: containerRef.current,
        // @ts-ignore
        renderer: "canvas",
        rendererSettings: {
          preserveAspectRatio: "xMidYMid meet",
          ...rendererSettings,
        },
      })
    }
  }

  useEffect(() => {
    createAnimation()

    const onResize = () => {
      animationRef?.current?.resize()
    }
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
      animationRef.current?.destroy()
    }
  }, [])

  return <div className={className} ref={containerRef} {...otherProps} />
}
