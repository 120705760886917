import { createContext } from "react"
import HtmlParser from "react-html-parser"
import { PageData } from "api"
import type { Transform } from "react-html-parser"

export const DEFAULT_LOCALE = "en"

export function getPlural(n: number) {
  n %= 100
  if (n >= 5 && n <= 20) {
    return "many"
  }
  n %= 10
  if (n === 1) {
    return "one"
  }
  if (n >= 2 && n <= 4) {
    return "few"
  }
  return "many"
}

export const LocaleContext = createContext<PageData>({
  locales: {},
  app_urls: {},
})

export function getHumanReadableLanguageName(locale: string) {
  switch (locale) {
    case "ru":
      return "РУС"
    default:
      return locale
  }
}

export function removeTags(html: string): string {
  const transform: Transform = (node) => {
    if (node.type === "text") {
      return node.data
    }
    if (node.children) {
      return node.children.map(transform).join("")
    }
  }

  return HtmlParser(html, { transform }).join("")
}
