import { useEffect, useState } from "react"
import { Hydrate, QueryClient, QueryClientProvider } from "react-query"
import { initGTM, isServer, Device } from 'lib'
import type { AppProps, AppContext } from "next/app"

import "swiper/css"
import "swiper/css/autoplay"
import "styles/global.scss"

export default function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: parseInt(process.env.NEXT_PUBLIC_STALE_TIME || "0"),
          },
        },
      }),
  )

  useEffect(() => {
    initGTM()
    if (!isServer()) {
      window.history.scrollRestoration = "manual"
      Device.initUaParser(window.navigator.userAgent)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      {/* @ts-ignore */}
      <Hydrate state={pageProps.dehydratedState}>
        {/* @ts-ignore */}
        <Component {...pageProps} />
      </Hydrate>
    </QueryClientProvider>
  )
}
