import { css, FlattenInterpolation, ThemeProps } from "styled-components"
import { isSafe } from "lib"

export type TUnit = number | string
export type TOmit = false | null
export type TCssResult = FlattenInterpolation<ThemeProps<any>>

export interface MainCommonProps {
  width?: TUnit
  minWidth?: TUnit
  maxWidth?: TUnit
  height?: TUnit
  minHeight?: TUnit
  maxHeight?: TUnit
  margin?: TUnit
  marginTop?: TUnit
  marginBottom?: TUnit
  marginLeft?: TUnit
  marginRight?: TUnit
  padding?: TUnit
  paddingLeft?: TUnit
  paddingRight?: TUnit
  paddingTop?: TUnit
  paddingBottom?: TUnit
  position?: "relative" | "absolute"
  top?: TUnit
  left?: TUnit
  bottom?: TUnit
  right?: TUnit
  zIndex?: number
  opacity?: number
  selection?: boolean
  visibility?: boolean
  hidden?: boolean
  transform?: string
}

export interface CommonProps extends MainCommonProps {
  mediaCommon?: {
    laptop?: MainCommonProps
    large_desktop?: MainCommonProps
    desktop?: MainCommonProps
    touch?: MainCommonProps
    tablet?: MainCommonProps
    mobile?: MainCommonProps
    desktop_new?: MainCommonProps
    tablet_new?: MainCommonProps
    laptop_small?: MainCommonProps
  }
}

export interface CommonPropsOptions {
  isWidth?: boolean
  isHeight?: boolean
}

export const applyCommonProps = (
  props: CommonProps,
  options: CommonPropsOptions = {},
) => {
  const { isWidth = true, isHeight = true } = options

  const resolve = {
    minWidth: props.minWidth,
    maxWidth: props.maxWidth,
    minHeight: props.minHeight,
    maxHeight: props.maxHeight,
    margin: props.margin,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight,
    padding: props.padding,
    paddingTop: props.paddingTop,
    paddingLeft: props.paddingLeft,
    paddingBottom: props.paddingBottom,
    paddingRight: props.paddingRight,
    position: props.position,
    top: props.top,
    left: props.left,
    bottom: props.bottom,
    right: props.right,
    zIndex: props.zIndex,
    opacity: props.opacity,
    selection: props.selection ?? true,
    visibility: props.visibility ?? true,
    hidden: props.hidden,
    transform: props.transform,
    mediaCommon: props.mediaCommon,
  }
  // @ts-ignore
  if (isWidth) resolve.width = props.width
  // @ts-ignore
  if (isHeight) resolve.height = props.height

  return resolve
}

export const unsetCommonProps = (props: CommonProps) => {
  delete props.width
  delete props.minWidth
  delete props.maxWidth
  delete props.height
  delete props.minHeight
  delete props.maxHeight
  delete props.margin
  delete props.marginTop
  delete props.marginBottom
  delete props.marginLeft
  delete props.marginRight
  delete props.padding
  delete props.paddingTop
  delete props.paddingLeft
  delete props.paddingBottom
  delete props.paddingRight
  delete props.position
  delete props.top
  delete props.left
  delete props.bottom
  delete props.right
  delete props.zIndex
  delete props.opacity
  delete props.selection
  delete props.visibility
  delete props.hidden
  delete props.transform
  delete props.mediaCommon
}

export const unit2css = (
  field?: TUnit,
  omit: TOmit = false,
): string | TOmit => {
  if (!isSafe(field)) return omit
  if (typeof field === "number") return `${field}px`
  // @ts-ignore
  if (Spacing[field as string]) return `${Spacing[field as string]}px`
  return field ?? omit
}

const generateCss = (
  currentProps: MainCommonProps,
  isWidth: boolean,
  isHeight: boolean,
) => css`
  width: ${isWidth ? unit2css(currentProps.width) : false};
  min-width: ${unit2css(currentProps.minWidth)};
  max-width: ${unit2css(currentProps.maxWidth)};
  min-height: ${unit2css(currentProps.minHeight)};
  max-height: ${unit2css(currentProps.maxHeight)};
  height: ${isHeight ? unit2css(currentProps.height) : false};
  margin: ${unit2css(currentProps.margin)};
  margin-top: ${unit2css(currentProps.marginTop)};
  margin-left: ${unit2css(currentProps.marginLeft)};
  margin-bottom: ${unit2css(currentProps.marginBottom)};
  margin-right: ${unit2css(currentProps.marginRight)};

  padding: ${unit2css(currentProps.padding)};
  padding-left: ${unit2css(currentProps.paddingLeft)};
  padding-right: ${unit2css(currentProps.paddingRight)};
  padding-top: ${unit2css(currentProps.paddingTop)};
  padding-bottom: ${unit2css(currentProps.paddingBottom)};

  position: ${currentProps.position};
  top: ${isSafe(currentProps.position) ? unit2css(currentProps.top) : false};
  left: ${isSafe(currentProps.position) ? unit2css(currentProps.left) : false};
  bottom: ${isSafe(currentProps.position)
    ? unit2css(currentProps.bottom)
    : false};
  right: ${isSafe(currentProps.position)
    ? unit2css(currentProps.right)
    : false};
  z-index: ${currentProps.zIndex};

  opacity: ${currentProps.opacity};

  user-select: ${isSafe(currentProps.selection)
    ? !currentProps.selection
      ? "none"
      : false
    : false};
  visibility: ${isSafe(currentProps.visibility)
    ? !currentProps.visibility
      ? "hidden"
      : false
    : false};
  display: ${isSafe(currentProps.hidden)
    ? currentProps.hidden
      ? "none"
      : false
    : false};

  transform: ${currentProps.transform};
`

export const commonPropsToCss = (
  props: CommonProps,
  options: CommonPropsOptions = {},
) => {
  const { isWidth = true, isHeight = true } = options

  return css`
    ${generateCss(props, isWidth, isHeight)}

    ${mediaPropsToCss("mediaCommon", props, (v: CommonProps) =>
      generateCss(
        { ...v, position: v.position ?? props.position },
        isWidth,
        isHeight,
      ),
    )}
  `
}

export const BreakPoints = Object.freeze({
  laptop: 1280 as 1280,
  large_desktop: 1440 as 1440,
  desktop: 1024 as 1024,
  tablet: 768 as 768,
  xs_mobile: 375 as 375,
  small: 640 as 640,
  medium: 1007 as 1007,
  large: 1008 as 1008,
  laptop_small: 1024 as 1024,
})

export const ViewPorts = {
  laptop: `@media only screen and (min-width: ${
    BreakPoints.laptop
  }px) and (max-width: ${BreakPoints.large_desktop - 1}px)`,
  large_desktop: `@media only screen and (min-width: ${BreakPoints.large_desktop}px)`,
  desktop: `@media only screen and (min-width: ${BreakPoints.laptop}px)`,
  desktop_new: `@media only screen and (min-width: ${BreakPoints.desktop}px)`,
  touch: `@media only screen and (max-width: ${BreakPoints.laptop - 1}px)`,
  tablet: `@media only screen and (min-width: ${
    BreakPoints.tablet
  }px) and (max-width: ${BreakPoints.laptop - 1}px)`,
  mobile: `@media only screen and (max-width: ${BreakPoints.tablet - 1}px)`,
  laptop_small: `@media only screen and (min-width: ${
    BreakPoints.laptop_small
  }px) and (max-width: ${BreakPoints.laptop - 1}px)`,
  tablet_new: `@media only screen and (min-width: ${
    BreakPoints.tablet
  }px) and (max-width: ${BreakPoints.laptop_small - 1}px)`,
}

export function mediaPropsToCss<T>(
  mediaKey: string,
  // @ts-ignore
  props,
  generateCss: (params: T) => TCssResult,
): TCssResult {
  return css`
    ${props?.[mediaKey]?.laptop &&
    css`
      ${ViewPorts.laptop} {
        ${generateCss(props?.[mediaKey]?.laptop)}
      }
    `}

    ${props?.[mediaKey]?.large_desktop &&
    css`
      ${ViewPorts.large_desktop} {
        ${generateCss(props?.[mediaKey]?.large_desktop)}
      }
    `}

    ${props?.[mediaKey]?.desktop &&
    css`
      ${ViewPorts.desktop} {
        ${generateCss(props?.[mediaKey]?.desktop)}
      }
    `}

    ${props?.[mediaKey]?.touch &&
    css`
      ${ViewPorts.touch} {
        ${generateCss(props?.[mediaKey]?.touch)}
      }
    `}

    ${props?.[mediaKey]?.tablet &&
    css`
      ${ViewPorts.tablet} {
        ${generateCss(props?.[mediaKey]?.tablet)}
      }
    `}

    ${props?.[mediaKey]?.laptop_small &&
    css`
      ${ViewPorts.laptop_small} {
        ${generateCss(props?.[mediaKey]?.laptop_small)}
      }
    `}

    ${props?.[mediaKey]?.tablet_new &&
    css`
      ${ViewPorts.tablet_new} {
        ${generateCss(props?.[mediaKey]?.tablet_new)}
      }
    `}

    ${props?.[mediaKey]?.mobile &&
    css`
      ${ViewPorts.mobile} {
        ${generateCss(props?.[mediaKey]?.mobile)}
      }
    `}

    ${props?.[mediaKey]?.desktop_new &&
    css`
      ${ViewPorts.desktop_new} {
        ${generateCss(props?.[mediaKey]?.desktop_new)}
      }
    `}

    ${props?.[mediaKey]?.tablet_new &&
    css`
      ${ViewPorts.tablet_new} {
        ${generateCss(props?.[mediaKey]?.tablet_new)}
      }
    `}
  `
}
