export function initGTM() {
  const gtmScript = document.createElement("script")
  gtmScript.async = true
  gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5V5BZQR');
    `

  const gtmNoscript = document.createElement("noscript")
  gtmNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5V5BZQR"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `

  document.head.append(gtmScript)
  document.body.append(gtmNoscript)
}
