import type { NextPageContext } from "next"

import { isServer } from "lib"

export function getHost(locale: string = ""): string {
  const host = process.env.NEXT_PUBLIC_HOST || "https://cityguides.pure.app"

  return `${host}/${locale}`
}

export function getArticleUrl(slug: string) {
  return `/articles/${slug}`
}

export function getCityUrl(slug: string) {
  return `/city/${slug}`
}

export function getMainPageUrl(locale?: string) {
  return `/${locale === "en" ? "" : locale}`
}

export function btoa(encode: string) {
  if (isServer()) {
    return Buffer.from(encode).toString('base64')
  } else {
    return window.btoa(encode)
  } 
}

export function atob(decode: string) {
  if (isServer()) {
    return Buffer.from(decode, 'base64').toString()
  } else {
    return window.atob(decode)
  } 
}
