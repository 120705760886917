// @ts-ignore
export const isSafe = (v) => {
  return v !== undefined && v !== null
}

export const safeObject = (instance: unknown) => {
  const resolve = {}
  // @ts-ignore
  Object.entries(instance).forEach(([key, value]) => {
    // @ts-ignore
    if (value !== undefined && value !== null) resolve[key] = value
  })

  return resolve
}
