export function mergeClasses(
  defaultClasses: string,
  className: string | undefined,
) {
  if (className && defaultClasses) {
    return mergeClassesAlgorithm(defaultClasses, className)
  }

  return defaultClasses
}

function mergeClassesAlgorithm(defaultClasses: string, className: string) {
  let newClasses = className.split(" ")
  let oldClasses = defaultClasses.split(" ")
  const newClassesClassPrefix = getClassPrefix(newClasses)
  const oldClassesClassPrefix = getClassPrefix(oldClasses)

  for (let index = 0; index < newClasses.length; index++) {
    let searchIndex = oldClassesClassPrefix.indexOf(
      newClassesClassPrefix[index],
    )
    if (matchFound(searchIndex)) {
      oldClasses[searchIndex] = newClasses.slice(index, index + 1)[0]
      continue
    }
    oldClasses.push(newClasses.slice(index, index + 1)[0])
  }

  return oldClasses.join(" ")
}

function matchFound(searchIndex = -1) {
  if (searchIndex !== -1) {
    return true
  }
  return false
}

function getClassPrefix(classes: string[]) {
  if (classes.length) {
    return classes.map((className) => {
      return className.split("-")[0]
    })
  }
  return classes
}
