import { useEffect, useState } from "react"
import UAParser from "ua-parser-js"
import { isServer } from "./isomorphic"
import type { IResult } from "ua-parser-js"

class DeviceModel {
  private parsedUA: IResult

  constructor() {
    const parsedUA = new UAParser()
    this.parsedUA = parsedUA.getResult()
  }

  initUaParser = (uastring?: string) => {
    const parsedUA = new UAParser(uastring)
    this.parsedUA = parsedUA.getResult()
  }

  isAndroid = () => this.parsedUA.os.name?.toLowerCase() === "android"

  isIOS = () => this.parsedUA.os.name?.toLowerCase() === "ios"

  isWindows = () => this.parsedUA.os.name?.toLowerCase() === "windows"

  isMobile = () => this.parsedUA.device.type === "mobile"

  isTablet = () => this.parsedUA.device.type === "tablet"

  isDesktop = () => !this.isMobile() && !this.isTablet()

  isWebPSupported = () => {
    const browserMajorVersion = Number(
      (this.parsedUA.browser.version ?? "").split(".")[0],
    )
    const osMajorVersion = Number(
      (this.parsedUA.os.version ?? "").split(".")[0],
    )

    if ((this.parsedUA.browser.name || "") in ["IE", "IEMobile"]) {
      return false
    }

    if (this.parsedUA.browser.name === "Safari") {
      if (this.parsedUA.os.name === "iOS" && browserMajorVersion < 14) {
        return false
      }

      if (this.parsedUA.os.name === "Mac OS" && browserMajorVersion < 16) {
        if (osMajorVersion < 11) {
          return false
        }
      }
    }

    return true
  }

  getStoresAccess = () => ({
    appStore: Device.isIOS() || Device.isDesktop(),
    androidStores: Device.isAndroid() || Device.isDesktop(),
  })
}

export const Device = new DeviceModel()

export function useWindowWidth() {
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    if (!isServer()) {
      setWidth(window.innerWidth)
    }
    function onResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", onResize)
    return function cleanup() {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return width
}
