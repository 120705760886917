import axios from "axios"
import type { AxiosInstance } from "axios"
import { DEFAULT_LOCALE } from "./localization"

export type HttpParams = Record<string, unknown> & {
  locale?: string
}

class HttpProvider {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_URL,
    })
  }

  async get(url: string, params?: HttpParams): Promise<unknown> {
    const { locale, ...restParams } = params || { locale: DEFAULT_LOCALE }
    const response = await this.axiosInstance.get(url, {
      params: {
        _locale: locale,
        ...restParams,
      },
    })
    //console.log(`GET ${url}\n ${JSON.stringify(params)}\n`, response.data)
    return response.data
  }
}

export const Http = new HttpProvider()
